import styled from "styled-components";
import { FunctionComponent } from "react";
import { Button, Form, FormInstance, Input } from "antd";
import { isValidOrgNummer } from "../../lib/helpers";

interface BusinessFormProps {
  formRef: FormInstance<any>;
  handleClick: () => void;
  errorMsg: string | null;
  loading: boolean;
}

const validateOrgNummer: (rule: any, value: string) => Promise<void> = (
  rule,
  value
) => {
  //TODO: Check if rule is necessary
  console.log(rule);

  if (isValidOrgNummer(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Fyll i ett korrekt organisationsnummer");
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} är obligatoriskt",
  types: {
    email: "Fyll i en giltig e-postadress",
    number: "${label} is not a valid number",
    password: "Fyll i ett lösenord",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const BusinessForm: FunctionComponent<BusinessFormProps> = ({
  formRef,
  handleClick,
  loading,
  /*  errorMsg, */
}) => {
  return (
    <Container>
      {/*  <Column>
        <h2>Välkommen till Graftus!</h2>
        <h3>Berätta lite mer om ditt företag.</h3>
        <p>
          Fortsätt genom att fylla i dina uppgifter och låt oss hjälpa dig att
          hitta de bästa säljarna för ditt företag.
        </p>
        <p>
          Vi ser fram emot att arbeta med dig och göra din rekryteringsprocess
          så smidig och effektiv som möjligt.
        </p>
      </Column> */}
      <Column>
        <h2>Välkommen till Graftus!</h2>
        <Form
          form={formRef}
          onFinish={() => {
            handleClick();
          }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Var vänlig och fyll i ditt namn" },
            ]}
          >
            <Input placeholder="Ditt namn" />
          </Form.Item>
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "Var vänlig och fyll i ditt företagsnamn",
              },
            ]}
          >
            <Input placeholder="Företag" />
          </Form.Item>
          <Form.Item
            name="companyNumber"
            rules={[
              {
                required: true,
                validator: validateOrgNummer,
                message: "Fyll i ett korrekt organisationsnummer",
              },
            ]}
          >
            <Input placeholder="Organisationsnummer" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Fortsätt till plattformen
            </Button>
          </Form.Item>
        </Form>
      </Column>
    </Container>
  );
};

export default BusinessForm;

const Container = styled.div`
  display: flex;
  flex: 100%;

  h2 {
    margin-top: 0 !important;
  }
`;

const Column = styled.div`
  padding: 2rem 2rem;
  width: 100%;
`;
