import { initializeApp } from "firebase/app";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";
import { toast } from "@/hooks/use-toast";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, "europe-west1");
const messaging = getMessaging(app);

const getTokenLocal = async (setTokenFound: (tokenFound: boolean) => void) => {
  console.log('🚀 Starting getTokenLocal process...');
  try {
    const swName = import.meta.env.PROD
      ? "/firebase-messaging-sw.js"
      : "/firebase-messaging-sw-dev.js";
    console.log('📝 Service Worker path:', swName);
    
    let swRegistration;
    try {
      console.log('🔍 Checking for existing Service Worker registration...');
      swRegistration = await navigator.serviceWorker.getRegistration();
      if (!swRegistration) {
        console.log('⚙️ No SW found, registering new Service Worker...');
        swRegistration = await navigator.serviceWorker.register(swName);
        console.log('✅ Service Worker registered successfully:', swRegistration);
      } else {
        console.log('✅ Found existing Service Worker:', swRegistration);
      }
    } catch (swError) {
      console.error('❌ Service Worker registration failed:', swError);
      setTokenFound(false);
      return;
    }

    if (!("Notification" in window)) {
      console.error('❌ Notifications not supported in this browser');
      setTokenFound(false);
      return;
    }
    console.log('✅ Notifications are supported');

    console.log('🔔 Requesting notification permission...');
    const permission = await Notification.requestPermission();
    console.log('📢 Notification permission status:', permission);
    if (permission !== "granted") {
      console.error('❌ Notification permission denied');
      setTokenFound(false);
      return;
    }

    const vapidKey = import.meta.env.VITE_FIREBASE_VAPID_KEY;
    console.log('🔑 VAPID Key length:', vapidKey?.length);
    if (!vapidKey || vapidKey.length < 80) {
      console.error('❌ Invalid VAPID key configuration. Length:', vapidKey?.length);
      setTokenFound(false);
      return;
    }

    console.log('🎯 Attempting to get FCM token...');
    const currentToken = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration: swRegistration,
    });
    console.log('📱 FCM Token received:', currentToken ? '✅ Token exists' : '❌ No token');

    if (currentToken && auth?.currentUser?.uid) {
      console.log('👤 Current user UID:', auth.currentUser.uid);
      const docRef = doc(db, "Businesses", auth.currentUser.uid);
      await updateDoc(docRef, {
        fcmToken: currentToken,
      });
      console.log('💾 Token saved to Firestore');
      setTokenFound(true);
    } else {
      console.error('❌ No token or user:', {
        hasToken: !!currentToken,
        hasUser: !!auth?.currentUser,
        userId: auth?.currentUser?.uid
      });
      setTokenFound(false);
    }
  } catch (err) {
    console.error('❌ Fatal error in getTokenLocal:', err);
    setTokenFound(false);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log('🎧 Setting up message listener...');
    onMessage(messaging, (payload) => {
      console.log("📨 Received FCM message:", payload);
      
      toast({
        title: payload?.notification?.title || "New Message",
        description: payload?.notification?.body,
        action: payload?.data?.jobAdId ? (
          <Button asChild>
            <Link
              to={`/chat/${payload?.data?.jobAdId}/${payload?.data?.candidateId}`}
            >
              Chatta
            </Link>
          </Button>
        ) : undefined,
      });

      // Update unread messages count
      const unreadMessages = localStorage.getItem("unreadMessages");
      localStorage.setItem(
        "unreadMessages",
        ((parseInt(unreadMessages || "0") + 1)).toString()
      );
      
      console.log("📬 Updated unread messages count:", localStorage.getItem("unreadMessages"));
      resolve(payload);
    });
  });

export { app, db, auth, storage, functions, messaging, getTokenLocal };
