import { Button, Form, FormInstance, Input } from "antd";
import { FunctionComponent } from "react";
import styled from "styled-components";

interface UserFormProps {
  formRef: FormInstance<any>;
  handleClick: (values: any) => void;
  errorMsg: string | null;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} är obligatoriskt",
  types: {
    email: "Fyll i en giltig e-postadress",
    number: "${label} is not a valid number",
    password: "Fyll i ett lösenord",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

// is errorMsg necessary?
const UserForm: FunctionComponent<UserFormProps> = ({
  formRef,
  handleClick,
  /* errorMsg, */
}) => {
  // TODO: Set on finish
  /* const onFinish = (values: any) => {
    console.log("Success:", values);
  }; */

  const validatePassword: (rule: any, value: string) => Promise<void> = (
    rule,
    value
  ) => {
    console.log(rule);

    if (value === formRef.getFieldValue("password")) {
      return Promise.resolve();
    }
    return Promise.reject("Fyll i ett korrekt organisationsnummer");
  };

  return (
    <Container>
      <Column>
        <h2>Skapa ditt företagskonto</h2>
        <Form
          form={formRef}
          onFinish={handleClick}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Var vänlig och fyll i din e-post!" },
              { type: "email" },
            ]}
          >
            <Input placeholder="E-postadress" autoComplete="username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Var vänlig och fyll i ditt lösenord!",
              },
            ]}
          >
            <Input.Password
              placeholder="Lösenord"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="passwordRepeat"
            rules={[
              {
                required: true,
                message: "Var vänlig och upprepa ditt lösenord!",
                validator: validatePassword,
              },
            ]}
          >
            <Input.Password
              placeholder="Upprepa lösenord"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Skapa konto
            </Button>
          </Form.Item>
        </Form>

        <FineText>
          Genom att fortsätta med registreringen godkänner du våra{" "}
          <a href="https://graftus.ai/villkor-foretag">villkor</a> och vår{" "}
          <a href="https://graftus.ai/integritetspolicy">integritetspolicy</a>
        </FineText>
      </Column>
      {/* <Column
        style={{
          backgroundColor: "#3a7a8e",
          color: "white",
          borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem",
        }}
      >
        <h2>Hitta säljare inom 10 minuter.</h2>
        <h3>Kandidater väntar på dig.</h3>
        <Checklist>
          <dl>
            <span color="#eeacb8" />
            <CheckOutlined /> Kostnadseffektiv rekryteringsplattform
          </dl>
          <dl>
            <span color="#eeacb8" /> <CheckOutlined /> Matcha lönenivå
          </dl>

          <dl>
            <span color="#eeacb8" /> <CheckOutlined /> Ta emot
            spontanansökningar enkelt
          </dl>
          <dl>
            <span color="#eeacb8" />
            <CheckOutlined /> Matcha obegränsat med kandidater
          </dl>
        </Checklist>
        <FineText>
          Har du redan ett konto? <Link to="/sign-in">Logga in</Link>
        </FineText>
      </Column> */}
    </Container>
  );
};

export default UserForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 0 !important;
  }
`;

const Column = styled.div`
  padding: 2rem 2rem;
  width: 100%;
`;

const FineText = styled.span`
  font-size: 12px;

  a {
    color: #515bc8;
  }
`;
