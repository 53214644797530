import { DocumentData, QuerySnapshot } from "firebase/firestore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { JobAd } from "@/types/jobAdsTypes";
import { useEffect, useState } from "react";

interface JobAdsListProps {
  jobAdsSnapshot: QuerySnapshot<DocumentData>;
  onAdClick: (ad: JobAd) => void;
  selectedAd: JobAd | null;
}

export default function JobAdsDropdown({
  jobAdsSnapshot,
  onAdClick,
  selectedAd,
}: JobAdsListProps) {
  console.log("first selectedAd", selectedAd);
  const [adItems, setAdItems] = useState<JobAd[]>([]);

  useEffect(() => {
    setAdItems(
      jobAdsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as JobAd[]
    );
  }, [jobAdsSnapshot, selectedAd]);

  if (adItems.length === 0) {
    return null;
  }

  return (
    <Select
      onValueChange={(value) =>
        onAdClick(adItems.find((ad) => ad.id === value)!)
      }
      value={selectedAd?.id || ""}
    >
      <SelectTrigger className="w-[270px] h-fit bg-white dark:bg-background">
        <SelectValue placeholder="Select a Job Ad">
          {selectedAd && (
            <div className="flex items-center justify-between space-x-2">
              <span className="text-sm truncate">
                {selectedAd.aboutTheAdData.roleTitle}
              </span>
              {selectedAd.adType === "automated" ||
              selectedAd.adType === "automated-paused" ? (
                <Badge className="ml-2 bg-gradient-to-r from-purple-600 to-blue-500">
                  Julia AI
                </Badge>
              ) : (
                <Badge className="ml-2">Manuell</Badge>
              )}
            </div>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="w-full max-h-[400px]">
        {adItems.map((ad) => {
          return (
            <SelectItem
              key={ad.id}
              value={ad.id}
              className="flex flex-col items-start p-2 space-y-1 cursor-pointer hover:bg-accent"
            >
              <div className="flex justify-between w-full">
                <span className="font-medium">
                  {ad.aboutTheAdData.roleTitle}
                </span>
              </div>
              <span className="text-sm text-muted-foreground flex justify-between w-full">
                {ad.adType === "automated" ||
                ad.adType === "automated-paused" ? (
                  <Badge className="bg-gradient-to-r from-purple-600 to-blue-500">
                    Julia AI
                  </Badge>
                ) : (
                  <Badge variant="secondary"> Manuell</Badge>
                )}
                <Badge variant="secondary" className="ml-2">
                  {ad.generalMenuData.region.regionInner}
                </Badge>
                <Badge variant="outline" className="ml-2">
                  {!ad.isDraft && ad.createdAt
                    ? //@ts-ignore
                      `${ad.createdAt.toDate().toLocaleDateString()}`
                    : "Utkast"}
                </Badge>
              </span>
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}
