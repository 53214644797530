import { useAdRedirect } from "@/lib/useAds";
import { useUsers } from "@/context/UsersContext";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/config/firebaseConfig";
import { Candidate } from "@/types/candidate";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Skeleton } from "./ui/skeleton";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import NewChatsList from "./Chat/new-chats-list";
import JobAdsDropdown from "./job-ads-dropdown";

export default function ChatMobileList() {
  const { selectedAd, adsSnapshot, navigateToAd } = useAdRedirect("/chat");
  const { id: id, userId: selectedCandidate } = useParams<{
    id?: string;
    userId?: string;
  }>();
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [senderFilter, setSenderFilter] = useState("all");

  console.log("selectedCandidate", selectedCandidate);

  const { users } = useUsers();
  const matchedUsersRef = collection(db, `TopLevelReciprocalMatches`);
  const matchedUsersQuery = id
    ? query(
        matchedUsersRef,
        where("jobAdId", "==", id),
        orderBy("timestamp", "desc")
      )
    : null;
  const [matchedUsers, matchedUsersLoading, matchedUsersError] =
    useCollectionData(matchedUsersQuery);
  const [sortedMatches, setSortedMatches] = useState<Candidate[]>([]);

  useEffect(() => {
    const fetchLatestMessageTimestamps = async () => {
      if (!matchedUsers) {
        setSortedMatches([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const matchesWithMessages = await Promise.all(
        matchedUsers.map(async (match) => {
          const messagesRef = collection(db, `Chats/${id}/Messages`);
          const latestMessageQuery = query(
            messagesRef,
            where("sellerUid", "==", match.candidateId),
            orderBy("timestamp", "desc"),
            limit(1)
          );
          const messageSnapshot = await getDocs(latestMessageQuery);
          const latestMessage = messageSnapshot.docs[0]?.data();
          return { ...match, latestMessage };
        })
      );

      const sortedByLatestMessage = matchesWithMessages.sort((a, b) => {
        const timestampA = a.latestMessage?.timestamp?.toDate().getTime() || 0;
        const timestampB = b.latestMessage?.timestamp?.toDate().getTime() || 0;
        return timestampB - timestampA;
      });

      const sortedUsers = sortedByLatestMessage
        .map((match) => {
          //@ts-ignore
          const user = users.find((user) => user.uid === match.candidateId);
          return user
            ? { ...user, ...match, latestMessage: match.latestMessage }
            : null;
        })
        .filter(Boolean);

      setSortedMatches(sortedUsers);
      setIsLoading(false);
    };

    if (!matchedUsersLoading) {
      fetchLatestMessageTimestamps();
    }
  }, [matchedUsers, matchedUsersLoading, users, id]);

  const filteredMatches = sortedMatches.filter((match) => {
    const name = `${match.givenName || ""} ${match.surname || ""}`.trim();
    const nameMatches = name.toLowerCase().includes(searchTerm.toLowerCase());

    if (senderFilter === "all") {
      return nameMatches;
    } else if (senderFilter === "ai") {
      return (
        //@ts-ignore
        nameMatches && match.latestMessage?.sender === "business-automated"
      );
    } else if (senderFilter === "business") {
      //@ts-ignore
      return nameMatches && match.latestMessage?.sender === "business";
    } else if (senderFilter === "seller") {
      //@ts-ignore
      return nameMatches && match.latestMessage?.sender === "seller";
    }
    return nameMatches;
  });

  const activeMatches = filteredMatches.filter(
    (match) =>
      match.status !== "archived" &&
      match.status !== "hired" &&
      match.status !== "businessDeclined" &&
      match.status !== "candidateDeclined"
  );

  const archivedMatches = filteredMatches.filter(
    (match) =>
      match.status === "archived" ||
      match.status === "hired" ||
      match.status === "businessDeclined" ||
      match.status === "candidateDeclined"
  );

  return (
    <div className="flex flex-col h-full md:hidden">
      {matchedUsersError ? (
        <p className="p-4 text-red-500">
          Error: {JSON.stringify(matchedUsersError.message)}
        </p>
      ) : (
        <>
          {/* Header */}
          <div className="sticky top-0 bg-background z-10 border-b">
            <div className="p-4 space-y-4">
              {/* Job Ads Dropdown */}
              {adsSnapshot && (
                <div>
                  <Label htmlFor="job-ads-dropdown" className="mb-1">
                    Välj annons
                  </Label>
                  <JobAdsDropdown
                    jobAdsSnapshot={adsSnapshot}
                    onAdClick={navigateToAd}
                    selectedAd={selectedAd}
                  />
                </div>
              )}

              {/* Search and Filter */}
              <div className="space-y-4">
                <div>
                  <Label>Sök kandidat</Label>
                  <Input
                    className="w-full"
                    placeholder="T.ex. Erik Svensson..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div>
                  <Label>Senast svar från</Label>
                  <Select value={senderFilter} onValueChange={setSenderFilter}>
                    <SelectTrigger className="w-full bg-background">
                      <SelectValue placeholder="Filter" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">Alla</SelectItem>
                      <SelectItem value="ai">Julia AI</SelectItem>
                      <SelectItem value="business">Företag</SelectItem>
                      <SelectItem value="seller">Kandidat</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {/* Chat List */}
          <div className="flex-1 overflow-y-auto">
            {isLoading ? (
              // Loading skeletons
              <div className="p-4 space-y-4">
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <Skeleton className="h-12 w-12 rounded-full" />
                      <div className="space-y-2">
                        <Skeleton className="h-4 w-[200px]" />
                        <Skeleton className="h-4 w-[150px]" />
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <Tabs defaultValue="active" className="w-full">
                <TabsList className="sticky top-0 grid w-full grid-cols-2">
                  <TabsTrigger value="active">Aktiva</TabsTrigger>
                  <TabsTrigger value="archived">Arkiverade</TabsTrigger>
                </TabsList>
                <TabsContent value="active">
                  <NewChatsList
                    matches={activeMatches}
                    selectedAdId={selectedAd?.id!}
                  />
                </TabsContent>
                <TabsContent value="archived">
                  <NewChatsList
                    matches={archivedMatches}
                    selectedAdId={selectedAd?.id!}
                  />
                </TabsContent>
              </Tabs>
            )}
          </div>
        </>
      )}
    </div>
  );
}
