import ReactDOM from "react-dom/client";
import "./index.css";
import "antd/dist/reset.css";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./routes/Routes.tsx";
import { ConfigProvider, Empty } from "antd";
import { CrmProvider } from "./context/CrmContext.tsx";
import { UsersProvider } from "./context/UsersContext.tsx";
import { ProfileProvider } from "./context/ProfileContext.tsx";
import { ThemeProvider } from "./components/theme-provider.tsx";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://eb211213fac1c00a72b72a16fc231b74@o4508257702772736.ingest.de.sentry.io/4508257708408912",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const customizeRenderEmpty = () => (
  <Empty description="Här var det tomt">
    {/* <Button type="primary">Skapa en annons för att komma igång</Button> */}
  </Empty>
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  /*  <React.StrictMode> */
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#515BC8",
        colorInfo: "#515bc8",
        colorError: "#fa2558",
        colorSuccess: "#187c46",
      },
    }}
    renderEmpty={customizeRenderEmpty}
  >
    <CrmProvider>
      <ProfileProvider>
        <UsersProvider>
          <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
            <RouterProvider router={appRouter} />
          </ThemeProvider>
        </UsersProvider>
      </ProfileProvider>
    </CrmProvider>
  </ConfigProvider>
  /*   </React.StrictMode> */
);
