import "./App.css";
import styled from "styled-components";
import { ReactNode } from "react";

const UnAuthenticatedLayout = ({ children }: { children: ReactNode }) => {
  return <Container>{children}</Container>;
};

export default UnAuthenticatedLayout;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(
      269.86deg,
      rgba(0, 54, 255, 0.11) 0.27%,
      rgba(90, 83, 195, 0.11) 45.08%,
      rgba(158, 0, 255, 0.11) 98.54%
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
`;
