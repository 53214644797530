// CrmContext.tsx
import { doc, getDoc, increment, setDoc, updateDoc } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../config/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";

interface CrmData {
  // Define the structure of your CRM data here, for example:
  companyId: string;
  companyName: string;
  companyDomain: string;
  dashboardVisits?: number;
  trialStartDate?: any;
  totalImpressions: number;
  subscriptionType?: string | null;
  totalRecriprocalMatches: number;
  totalRecruitmentAds: number;
  totalSalespersonMatches: number;
  totalSuccessfulHires: number;
}

interface CrmContextProps {
  crmData: CrmData;
  setCrmData: React.Dispatch<React.SetStateAction<CrmData>>;
  createHubSpotBusiness: Function;
  registerTrialStart: Function;
  registerAdStatusChange: Function;
  registerSuccesfulHire: Function;
  registerReciprocalMatch: Function;
}

const CrmContext = createContext<CrmContextProps | undefined>(undefined);

export const useCrmContext = (): CrmContextProps => {
  const context = useContext(CrmContext);
  if (!context) {
    throw new Error("useCrmContext must be used within a CrmProvider");
  }
  return context;
};

interface CrmProviderProps {
  children: React.ReactNode;
}

// hubspotSearch.ts
type HubspotCompany = {
  id: string;
  properties: {
    name: string;
    domain: string;
  };
};

export const CrmProvider: React.FC<CrmProviderProps> = ({ children }) => {
  const [user, userLoading, userError] = useAuthState(auth);
  const [crmData, setCrmData] = useState<CrmData>({
    companyId: "",
    companyName: "",
    companyDomain: "",
    dashboardVisits: 0,
    subscriptionType: "",
    totalImpressions: 0,
    totalRecriprocalMatches: 0,
    totalRecruitmentAds: 0,
    totalSalespersonMatches: 0,
    totalSuccessfulHires: 0,
    trialStartDate: null,
  });

  //connectFunctionsEmulator(functions, "localhost", 5001);
  const registerHubSpotCompany = httpsCallable(
    functions,
    "registerHubSpotCompany"
  );
  const updateHubSpotCompany = httpsCallable(functions, "updateHubSpotCompany");
  const searchHubSpotCompany = httpsCallable(functions, "searchHubSpotCompany");

  useEffect(() => {
    (async () => {
      await fetchCrmData();
      //await registerTrialStart();
      //await createHubSpotBusiness("testman@testcat.se");
    })();
    return () => {};
  }, [user, userLoading, userError]);

  useEffect(() => {
    if (crmData && crmData) {
      (async () => {
        //await registerDashboardVisit();
      })();
    }
  }, [crmData]);

  const fetchCrmData = async () => {
    // Check if the user is logged in
    if (userLoading) {
      console.log("Auth state is still loading...");
      return;
    }

    if (userError) {
      console.error("Error in auth state: ", userError.message);
      return;
    }

    // Check if the user is logged in
    if (user) {
      const docRef = doc(db, "CrmData", user.uid);

      // Fetch the document data
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Call setCrmData with the fetched data
        setCrmData(docSnap.data() as CrmData);
      } else {
        console.log("No document found for the specified uid.");
      }
    } else {
      console.log("User is not logged in.");
    }
  };

  // Ca

  /**
   * Asynchronously creates a HubSpot business object based on the provided email address.
   * @function createHubSpotBusiness
   * @async
   * @param {string} email - The email address used to identify the target company.
   * @returns {Promise<string | null>} A Promise that resolves to the HubSpot Company ID (as a string) if the company is found, or null if an error occurs.
   * @throws Will throw an error if the response from the HubSpot API is not ok (non-200 status code).
   */
  const createHubSpotBusiness = async (
    email: string
  ): Promise<String | null> => {
    try {
      const domain = email.split("@")[1];
      let companies: HubspotCompany[] = [];

      // First, search for the company in HubSpot
      const response = await searchHubSpotCompany({ domain: domain });
      // console.log("response", response);

      // Set the companies array to the results of the search
      companies = (response as any).data.results;

      console.log("companies", companies);

      // If the company is not found, create it
      if (companies.length === 0) {
        const createCompanyResponse = await registerHubSpotCompany({
          domain: domain,
        });

        companies[0] = {
          id: (createCompanyResponse as any).data,
          properties: {
            name: domain,
            domain: domain,
          },
        };
      }

      console.log("companies after cret", companies);

      const hubId = companies[0].id;
      const obj: CrmData = {
        companyId: hubId,
        companyName: companies[0].properties.name,
        companyDomain: companies[0].properties.domain,
        dashboardVisits: 1,
        subscriptionType: null,
        totalImpressions: 0,
        totalRecriprocalMatches: 0,
        totalRecruitmentAds: 0,
        totalSalespersonMatches: 0,
        totalSuccessfulHires: 0,
        trialStartDate: null,
      };
      setCrmData(obj);
      // Add the document to the Firebase CrmData collection
      await setDoc(doc(db, "CrmData", user!.uid), obj);
      const properties = {
        graftus_recruitment_total_ads: 0,
        graftus_recruitment_total_impressions: 0,
        graftus_recruitment_total_salesperson_matches: 0,
        graftus_recruitment_total_reciprocal_matches: 0,
        graftus_recruitment_total_successful_hires: 0,
        graftus_usage_dashboard_total_visits: 1,
      };
      await updateHubSpotCompany({ companyId: hubId, properties: properties });

      console.log(`HubSpot Company ID: ${hubId}`);
      return hubId;
    } catch (error) {
      console.error("Error fetching company data:", error);
      return null;
    }
  };

  const registerReciprocalMatch = async () => {
    const docRef = doc(db, "CrmData", user!.uid);
    await updateDoc(docRef, {
      totalReciprocalMatches: increment(1),
    });
  };

  const registerSuccesfulHire = async () => {
    const docRef = doc(db, "CrmData", user!.uid);
    await updateDoc(docRef, {
      totalSucessfulHires: increment(1),
    });
  };

  const registerAdStatusChange = async (status: "increment" | "decrement") => {
    const docRef = doc(db, "CrmData", user!.uid);
    // Increment the dashboardVisits field by 1 in Firestore
    await updateDoc(docRef, {
      totalRecruitmentAds: increment(status === "increment" ? 1 : -1),
    });
  };

 /*  const registerDashboardVisit = async () => {
    const visitedKey = "dashboardVisited";

    if (!sessionStorage.getItem(visitedKey)) {
      console.log("Registering dashboard visit...");
      const docRef = doc(db, "CrmData", user!.uid);
      // Increment the dashboardVisits field by 1 in Firestore
      await updateDoc(docRef, {
        dashboardVisits: increment(1),
      });

      sessionStorage.setItem(visitedKey, "true");
    }
  }; */

  const registerTrialStart = async (companyId: string) => {
    // TODO: Is companyId necessary?
    console.log("Registering trial start...", companyId);
    const docRef = doc(db, "CrmData", user!.uid);
    const now = new Date();
    const todayAtMidnight = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    const timeStamp = todayAtMidnight.getTime();

    // Update the trialStartDate field with the current timestamp in Firestore
    await updateDoc(docRef, {
      trialStartDate: timeStamp,
      subscriptionType: "trial",
    });
  };

  return (
    <CrmContext.Provider
      value={{
        crmData,
        setCrmData,
        createHubSpotBusiness,
        registerTrialStart,
        registerAdStatusChange,
        registerSuccesfulHire,
        registerReciprocalMatch,
      }}
    >
      {children}
    </CrmContext.Provider>
  );
};
