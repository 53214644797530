import { FunctionComponent, useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { useParams } from "react-router-dom";
import { storage } from "../../config/firebaseConfig";
import { Candidate } from "../../types/candidate";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import MessageTimestamp from "../Chat/MessageTimestamp";
import { cn } from "@/lib/utils";

interface CandidateListItemProps {
  candidate: Candidate;
  selectedAdId: string;
  className?: string;
  previewMessage?: any;
}

const CandidateListItem: FunctionComponent<CandidateListItemProps> = ({
  candidate,
  previewMessage,
}) => {
  const [downloadURL, setDownloadURL] = useState<string | null>(null);

  if (!candidate) {
    return null;
  }

  const { userId: selectedCandidate } = useParams<{
    userId?: string;
  }>();

  let name = candidate.givenName;
  let firstName = name?.split(" ")[0];

  useEffect(() => {
    if (candidate.hasProfilePic) {
      const imageRef = ref(
        storage,
        `mobileUsersFiles/${candidate.uid}/profile_image`
      );
      getDownloadURL(imageRef)
        .then((url) => {
          setDownloadURL(url);
        })
        .catch((error) => {
          console.error("Error fetching image: ", error);
        });
    }
  }, [candidate, storage]);

  // Function to trim the message to the first sentence
  function trimMessageToMaxLength(message: string, maxChars: number) {
    if (!message) {
      return "";
    }
    if (message?.length <= maxChars) {
      return message; // Return the message as is if it's shorter than the max length
    } else {
      // If the message is longer, trim it to the maxChars - 3 (to accommodate the ellipsis) and add '...'
      return message.substring(0, maxChars - 3) + "...";
    }
  }

  const isUnread = previewMessage && previewMessage.readByBusiness === false;

  return (
    <div
      className={cn(
        "flex items-start gap-4 px-4 py-2 hover:bg-accent",
        selectedCandidate === candidate.uid && "bg-accent",
        isUnread && "bg-blue-50 dark:bg-blue-900"
      )}
    >
      <Avatar className="h-10 w-10">
        {downloadURL && (
          <AvatarImage
            src={downloadURL}
            alt={`${firstName}'s avatar`}
            className="object-cover"
          />
        )}
        <AvatarFallback>
          <span className="text-xl">
            {firstName?.[0]}
            {candidate.surname?.[0]}
          </span>
        </AvatarFallback>
      </Avatar>
      <div className="flex-1 space-y-1">
        <div className="flex items-center justify-between">
          <span className={cn("font-medium", isUnread && "font-bold")}>
            {firstName} {candidate.surname}
          </span>
          {previewMessage && (
            <span className="text-xs text-muted-foreground">
              <MessageTimestamp timestamp={previewMessage?.timestamp} />
            </span>
          )}
        </div>
        {/* <p className="text-sm font-medium">
          {age} år, {candidate.latestOccupation}
        </p> */}
        <div className="flex items-center justify-between">
          <p className={cn("text-xs text-muted-foreground line-clamp-2", isUnread && "font-semibold text-foreground")}>
            {trimMessageToMaxLength(previewMessage?.message, 40)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CandidateListItem;