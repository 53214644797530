import { CloseOutlined, HeartOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Avatar, Button, message, Space } from "antd";
import { JobAd } from "../../types/jobAdsTypes";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { useEffect, useState } from "react";

export default function FavoritePopup({
  selectedAd,
  selectedCandidate,
  messageLength,
}: {
  selectedAd: JobAd;
  selectedCandidate: string;
  messageLength: number;
}) {
  const [visible, setVisible] = useState(messageLength > 5);

  const setCandidateAsFavorite = async () => {
    if (!selectedAd || !selectedCandidate) {
      message.error("Kunde inte markera kandidaten som favorit");
      return;
    }

    try {
      const favoriteRef = doc(
        db,
        `JobAds/${selectedAd.id}/Favorites`,
        selectedCandidate
      );
      await setDoc(favoriteRef, {
        userId: selectedCandidate,
        timestamp: Timestamp.now(),
      });
      message.success(
        "Tack för feedbacken! Jag blir bättre på att hitta rätt kandidater åt dig genom din hjälp!"
      );
    } catch (error) {
      console.error("Error setting candidate as favorite:", error);
      message.error(
        "Något gick fel när kandidaten skulle markeras som favorit"
      );
    }
  };

  useEffect(() => {
    setVisible(messageLength > 5);
  }, [messageLength]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      style={{ marginBottom: 8 }}
      message={
        <Space>
          <Avatar icon={<UserOutlined />} />
          <Space direction="vertical" size={[0, 0]}>
            Tycker du att denna kandidat var en bra match?
            <span style={{ fontWeight: "italic", fontSize: 12 }}>Julia</span>
          </Space>
        </Space>
      }
      action={
        <Space>
          <Button icon={<HeartOutlined />} onClick={setCandidateAsFavorite}>
            Ja
          </Button>
          <Button icon={<CloseOutlined />} onClick={() => setVisible(false)}>
            Nej
          </Button>
        </Space>
      }
    ></Alert>
  );
}
