import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { auth } from "../config/firebaseConfig";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, Button, Alert, message } from "antd";
import UnAuthenticatedLayout from "../layouts/UnAuthenticatedLayout";

const ForgotPasswordView = () => {
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  const [form] = Form.useForm();

  const onFinish = async (values: unknown) => {
    const { email } = values as { email: string };
    const url = import.meta.env.VITE_APP_URL;

    const res = await sendPasswordResetEmail(email, {
      url,
    });

    if (res) {
      message.success(
        "Ett mail med instruktioner har skickats till din e-post!"
      );
      form.resetFields();
    } else {
      message.error("Något gick fel, var vänlig försök igen!");
    }
  };

  return (
    <UnAuthenticatedLayout>
      <FormLayout form={form} onFinish={onFinish}>
        {error && (
          <Alert
            type="error"
            message={error.message}
            style={{ marginBottom: 15 }}
          />
        )}
        <Logo src="/graftus_logo.jpg" alt="logo" />
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Var vänlig och fyll i din e-post!" },
          ]}
        >
          <Input placeholder="E-post" autoComplete="username" />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" block loading={sending}>
            Återställ lösenord
          </Button>
        </Form.Item>
        <Link to="/sign-in" style={{ textAlign: "center", color: "#9e00ff" }}>
          Tillbaka
        </Link>
      </FormLayout>
    </UnAuthenticatedLayout>
  );
};

export default ForgotPasswordView;

const FormLayout = styled(Form)`
  width: 350px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: auto;
`;

const Logo = styled.img`
  height: 52px;
  margin: auto;
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
`;
