import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function MatchActionsDialog({
  candidate,
  selectedAdId,
  setSelectedMatch,
  refreshUsers,
}: {
  candidate: any;
  selectedAdId: string;
  setSelectedMatch: (match: any) => void;
  refreshUsers: (callback: (prevCount: number) => number) => void;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [decision, setDecision] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleBusinessQuitProcessWithoutUser = async () => {
    try {
      console.log("BusinessQuitProcessWithoutUser", selectedAdId);

      await setDoc(
        doc(
          db,
          `JobAds/${selectedAdId}/BusinessQuitProcessWithUser`,
          candidate.uid
        ),
        {
          timestamp: Timestamp.now(),
          uid: candidate.uid,
        }
      );
      refreshUsers((prevCount) => prevCount + 1);
      setSelectedMatch(null);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUserQuitProcess = async () => {
    try {
      await setDoc(
        doc(db, `JobAds/${selectedAdId}/UsersQuitProcess`, candidate.uid),
        {
          timestamp: Timestamp.now(),
          uid: candidate.uid,
        }
      );
      await setDoc(
        doc(
          db,
          `JobAds/${selectedAdId}/BusinessSeenUsersQuitProcess`,
          candidate.uid
        ),
        {
          timestamp: Timestamp.now(),
          uid: candidate.uid,
        }
      );
      /*  await setDoc(
        doc(db, `TopLevelReciprocalMatches/${candidate.matchId}`),
        {
          status: "candidateDeclined",
        },
        { merge: true }
      ); */
      refreshUsers((prevCount) => prevCount + 1);
      setSelectedMatch(null);
    } catch (e) {
      console.log(e);
    }
  };

  const handleBusinessHiredCandidate = async () => {
    try {
      await setDoc(
        doc(db, `JobAds/${selectedAdId}/BusinessHiredCandidate`, candidate.uid),
        {
          timestamp: Timestamp.now(),
          uid: candidate.uid,
        }
      );
      /*  await setDoc(
        doc(db, `TopLevelReciprocalMatches/${candidate.matchId}`),
        {
          status: "hired",
        },
        { merge: true }
      ); */
      refreshUsers((prevCount) => prevCount + 1);
      setSelectedMatch(null);
      //registerSuccesfulHire();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSaveDecision = async () => {
    setIsLoading(true);
    console.log(decision);

    switch (decision) {
      case "end":
        await handleBusinessQuitProcessWithoutUser();
        break;
      case "candidate-ended":
        await handleUserQuitProcess();
        break;
      case "hired":
        await handleBusinessHiredCandidate();
        break;
      case "continue":
        // Handle continue case if needed
        break;
    }
    setIsLoading(false);
    setIsModalOpen(false);
    navigate(`/chat/${selectedAdId}`);
    window.location.reload();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger asChild>
        <Button>Hur vill ni gå vidare?</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Hur vill ni gå vidare?</DialogTitle>
          <DialogDescription>
            Välj nästa steg för kandidaten. Chatten kommer att arkiveras efter
            att ni gjort ert val.
          </DialogDescription>
        </DialogHeader>
        <div className="py-4">
          <RadioGroup value={decision} onValueChange={setDecision}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="end" id="end" />
              <Label htmlFor="end">Vi vill avsluta processen här!</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="candidate-ended" id="candidate-ended" />
              <Label htmlFor="candidate-ended">
                Kandidaten har avslutat processen!
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="hired" id="hired" />
              <Label htmlFor="hired">Vi har anställt kandidaten!</Label>
            </div>
          </RadioGroup>
        </div>
        <DialogFooter>
          <Button onClick={handleSaveDecision} disabled={isLoading}>
            {isLoading ? "Sparar..." : "Spara"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
