import { navMain } from "@/components/app-sidebar";

export function getPathTitle(path: string): string {
  const item = navMain.find(
    (item) =>
      item.url === path ||
      (path !== "/" && item.url !== "/" && path.startsWith(item.url))
  );
  return item ? item.title : "Unknown";
}
