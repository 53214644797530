import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Spin } from "antd";
import UserForm from "../components/SignUpForms/UserForm";
import styled from "styled-components";
import UnAuthenticatedLayout from "../layouts/UnAuthenticatedLayout";
import BusinessForm from "../components/SignUpForms/BusinessForm";
import { isEmpty } from "../lib/helpers";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { auth } from "../config/firebaseConfig";
import { createBusiness } from "../lib/firebaseFunctions";
import { useProfile } from "../context/ProfileContext";
//import app from "../../config/firebase";
//import { loadStripe } from "@stripe/stripe-js";
//import "../sign_in/signin.css";
//import { sendWelcomeEmail } from "../../utils/helperFunctions";
//import { useCrmContext } from "../../context/CrmContext";

interface CreateTrialPageProps {
  gotoStep?: number;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// TODO: Implement gotoStep
const OnboardingView: FunctionComponent<
  CreateTrialPageProps
> = (/* { gotoStep } */) => {
  // Core states
  //const [loading, setLoading] = useState(false);
  const { redirectStep } = useParams();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(
    redirectStep ? Number.parseInt(redirectStep) : 1
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>();
  // TODO: Implement loading and error handling
  const [user /* loadingUser, errorUser */] = useAuthState(auth);
  // TODO: Implement loading and error handling
  const [
    createUserWithEmailAndPassword /* , userCredential, loading, error */,
  ] = useCreateUserWithEmailAndPassword(auth);
  // TODO: Implement loading and error handling
  /* const [signOut, loadingSignOut, errorSignOut] = useSignOut(auth); */
  const { setProfileCreated } = useProfile();

  // Form Refs
  const [userForm] = Form.useForm();
  const [businessForm] = Form.useForm();

  if (user && step === 1) {
    setStep(2);
  }

  // Step 1: Register user credentials
  const registerUser = async () => {
    const { email, password, passwordRepeat } = userForm.getFieldsValue();
    if (isEmpty(email) || isEmpty(password) || isEmpty(passwordRepeat)) {
      console.log("Error");
      setErrorMessage("Fyll i alla fält!");
    } else if (password !== passwordRepeat) {
      setErrorMessage("Lösenorden matchar inte.");
    } else {
      try {
        setStep(0);
        await createUserWithEmailAndPassword(email, password);
        setErrorMessage(null);
        setStep(2);
      } catch (error: any) {
        setStep(1);
        setErrorMessage(error.message);
        console.log("Error creating user", { error });
      }
    }
    //setStep(2);
  };

  // Step 2: Register business credentials
  const registerBusiness = async () => {
    console.log(businessForm.getFieldsValue());
    if (!user) return;

    const { name, company, companyNumber } = businessForm.getFieldsValue();

    console.log(user);

    const businessData = {
      business: company,
      orgNumber: companyNumber,
      name: name,
      email: user?.email,
      sub_still_active: "active",
      plan: "premium",
      v2: true,
      firstTimeOpening: true,
      accountType: "premium",
    };
    try {
      setLoading(true);
      await createBusiness(businessData, user.uid);
      // After profile creation logic
      if (!setProfileCreated) {
        console.error("setProfileCreated is not defined");
        return;
      }
      setProfileCreated(true);
      navigate("/");
    } catch (error) {
      console.log("Error creating business", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UnAuthenticatedLayout>
      <div className="container-1">
        <FormContainer>
          {/* {user && (
            <>
              <span>Inloggad: {user.email}</span>
              <button onClick={signOut}>DEBUG LOGOUT</button>
            </>
          )} */}
          {step === 0 ? (
            <div style={{ padding: "2rem" }}>
              <h2>Laddar...</h2>
              <SpinnerContainer>
                <Spin size="large" />
              </SpinnerContainer>
            </div>
          ) : step === 1 ? (
            <UserForm
              formRef={userForm}
              handleClick={registerUser}
              errorMsg={errorMessage!}
            />
          ) : step === 2 ? (
            <BusinessForm
              formRef={businessForm}
              handleClick={registerBusiness}
              errorMsg={errorMessage!}
              loading={loading}
            />
          ) : null}
        </FormContainer>
      </div>
    </UnAuthenticatedLayout>
  );
};

export default OnboardingView;

const FormContainer = styled.div`
  font-family: sans-serif;
  background-color: white;
  border-radius: 0.5rem;
  margin: auto;
  width: 400px;
`;

const SpinnerContainer = styled.div`
  margin: auto;
  height: 100px;
`;
