import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import { app } from "../config/firebaseConfig";

interface UsersContextProps {
  users: any[]; // Update the type according to your user data
  loading: boolean;
  refetch: boolean;
  setRefetch: (refetch: boolean) => void;
}

const UsersContext = createContext<UsersContextProps | undefined>(undefined);

interface UsersProviderProps {
  children: ReactNode;
}

export const UsersProvider: React.FC<UsersProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const auth = getAuth(app);
  const db = getFirestore(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Fetch the users and set them to state
        const fetchUsers = async () => {
          try {
            const usersQuery = query(collection(db, "Users")); // Update the collection name accordingly
            const querySnapshot = await getDocs(usersQuery);
            const usersData = querySnapshot.docs.map((doc) => doc.data());
            //console.log("Users fetched", usersData.length);
            setUsers(usersData);
          } catch (error) {
            console.error("Error fetching users: ", error);
          } finally {
            setLoading(false);
            setRefetch(false);
          }
        };
        fetchUsers();
      } else {
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, db, refetch]);

  return (
    <UsersContext.Provider value={{ users, loading, refetch, setRefetch }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error("useUsers must be used within a UsersProvider");
  }
  return context;
};
