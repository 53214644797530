// useAdRedirect.ts
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../config/firebaseConfig";
import { collection, query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { JobAd } from "../types/jobAdsTypes";
import { useProfile } from "@/context/ProfileContext";

export const useAdRedirect = (baseUrl: string) => {
  const { businessProfile } = useProfile();
  const uid = businessProfile?.uid;
  const { id: adId } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [selectedAd, setSelectedAd] = useState<JobAd | null>(null);

  const jobAdsQuery = uid
    ? query(
        collection(db, "JobAds"),
        where("businessUid", "==", uid),
        where("adActive", "==", true)
      )
    : null;

  const [adsSnapshot, adsLoading, adsError] = useCollection(jobAdsQuery);

  useEffect(() => {
    if (adsSnapshot?.docs.length === 0 || !adsSnapshot) {
      return;
    }

    let adDoc;
    if (adId) {
      adDoc = adsSnapshot.docs.find((doc) => doc.id === adId);
      if (!adDoc) return;
      const adData = adDoc?.data() as JobAd;
      setSelectedAd({ ...adData, id: adDoc?.id });
    } else {
      const firstAd = adsSnapshot.docs[0];
      navigate(`${baseUrl}/${firstAd.id}`);
    }
  }, [adsSnapshot, adId, navigate, baseUrl]);

  const navigateToAd = (adDoc: any) => {
    console.log("adDoc", adDoc);
    navigate(`${baseUrl}/${adDoc.id}`);
  };

  return {
    selectedAd,
    adsSnapshot,
    adsLoading,
    adsError,
    setSelectedAd,
    navigateToAd,
  };
};
