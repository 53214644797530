import { Input, Skeleton, message } from "antd";
import {
  collection,
  doc,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebaseConfig";
import { useCollection } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Button } from "../ui/button";

type ReciprocalMatch = {
  jobAdId: string;
  candidateId: string;
  businessId: string;
  businessName: string;
  roleTitle: string;
  timestamp: Timestamp;
  notes?: string;
};

const MatchNotes = ({
  jobAdId,
  candidateId,
}: {
  jobAdId: string;
  candidateId: string;
}) => {
  const [notes, setNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  const q = query(
    collection(db, `TopLevelReciprocalMatches`),
    where("jobAdId", "==", jobAdId),
    where("candidateId", "==", candidateId)
  );
  const [snapshot, snapshotLoading, error] = useCollection(q);

  useEffect(() => {
    setEditing(false);
    if (!snapshotLoading && snapshot && snapshot?.docs.length > 0) {
      const matchData = snapshot.docs[0].data() as ReciprocalMatch;
      setNotes(matchData.notes ?? "");
    }
  }, [snapshot, snapshotLoading, jobAdId, candidateId]); // Depend on snapshot and IDs to refetch on changes

  const saveNotes = async () => {
    const matchId = snapshot?.docs[0]?.id;
    if (!matchId) return;
    try {
      setLoading(true);
      await updateDoc(doc(db, `TopLevelReciprocalMatches`, matchId), { notes });
      message.success("Anteckningarna sparades");
    } catch (e) {
      console.error(e);
      message.error("Något gick fel");
    } finally {
      setLoading(false);
      setEditing(false);
    }
  };

  if (snapshotLoading) return <Skeleton active paragraph />;
  if (error || !snapshot) return <p>Error: {error?.message}</p>;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Anteckningar</CardTitle>
        <CardDescription>Lägg till anteckningar om kandidaten</CardDescription>
      </CardHeader>

      <CardContent>
        <CustomTextArea
          disabled={!editing}
          value={notes} // Control this component with the notes state
          onChange={(e) => setNotes(e.target.value)}
        />
      </CardContent>
      <CardFooter>
        {editing ? (
          <Button onClick={saveNotes} disabled={loading}>
            Spara
          </Button>
        ) : (
          <Button onClick={() => setEditing(true)}>Redigera</Button>
        )}
      </CardFooter>
    </Card>
  );
};

export default MatchNotes;

const CustomTextArea = styled(Input.TextArea)`
  &.ant-input-disabled {
    background-color: transparent;
    color: inherit;
  }
`;
