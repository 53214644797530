import { Button } from "antd";
import UnAuthenticatedLayout from "../layouts/UnAuthenticatedLayout";

const ErrorView = () => {
  return (
    <UnAuthenticatedLayout>
      <h1>Oops!</h1>
      <p>Ett fel har uppstått. Vi ursäktar besväret.</p>
      <Button type="primary" href="/">
        Tillbaka till Graftus
      </Button>
    </UnAuthenticatedLayout>
  );
};

export default ErrorView;
