import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";

export const createBusiness = async (business: any, uid: string) => {
  console.log("Firebase function createBusiness", business);

  return setDoc(doc(db, "Businesses", uid), {
    business: business.business,
    orgNumber: business.orgNumber,
    name: business.name,
    email: business.email,
    sub_still_active: business.sub_still_active,
    plan: business.plan,
    timestamp: serverTimestamp(),
    uid: uid,
    paid: false,
    v2: business.v2,
    firstTimeOpening: business.firstTimeOpening,
    accountType: "premium",
  });
};
