import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import ErrorView from "../views/ErrorView";
import { auth } from "../config/firebaseConfig";
/* import ProfileView from "../views/ProfileView";
import DashboardView from "../views/DashboardView";
import ExploreView from "../views/ExploreView";
import AdsView from "../views/AdsView";
import SignInView from "../views/SignInView";
import SignUpView from "../views/SignUpView";
import DebugView from "../views/DebugView";
import MatchesView from "../views/MatchesView"; */
import { Suspense, lazy } from "react";
import { Spin } from "antd";
import ChatView from "../views/ChatView";
import ForgotPasswordView from "../views/ForgotPasswordView";
import OnboardingView from "../views/OnboardingVIew";

// Lazily loaded components
const DashboardView = lazy(() => import("../views/DashboardView"));
const ExploreView = lazy(() => import("../views/ExploreView"));
const MatchesView = lazy(() => import("../views/MatchesView"));
const AdsView = lazy(() => import("../views/AdsView"));
const ProfileView = lazy(() => import("../views/ProfileView"));
const DebugView = lazy(() => import("../views/DebugView"));
const SignInView = lazy(() => import("../views/SignInView"));
const SignUpView = lazy(() => import("../views/SignUpView"));
const AdminViewAds = lazy(() => import("../views/AdminView"));
const AdminViewBusinesses = lazy(() => import("../views/AdminBusinessView"));
const BusinessView = lazy(() => import("../views/BusinessView"));
const IntegrationsView = lazy(() => import("../views/IntegrationsView"));

// This function returns a promise that resolves with the user data
// when the authentication state changes
const getUserData = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <Suspense fallback={<Spin />}>
            <AppLayout />
          </Suspense>
        }
        loader={() => defer({ userPromise: getUserData() })}
        errorElement={<ErrorView />}
      >
        <Route
          path="/"
          element={
            <Suspense fallback={<Spin />}>
              <DashboardView />
            </Suspense>
          }
        />
        <Route
          path="/explore"
          element={
            <Suspense fallback={<Spin />}>
              <ExploreView />
            </Suspense>
          }
        />
        <Route
          path="/explore/:id"
          element={
            <Suspense fallback={<Spin />}>
              <ExploreView />
            </Suspense>
          }
        />
        <Route
          path="/matches"
          element={
            <Suspense fallback={<Spin />}>
              <MatchesView />
            </Suspense>
          }
        />
        <Route
          path="/matches/:id"
          element={
            <Suspense fallback={<Spin />}>
              <MatchesView />
            </Suspense>
          }
        />
        <Route
          path="/matches/:adId/:matchId/:candidateId"
          element={
            <Suspense fallback={<Spin />}>
              <MatchesView />
            </Suspense>
          }
        />
        <Route
          path="/ads"
          element={
            <Suspense fallback={<Spin />}>
              <AdsView />
            </Suspense>
          }
        />
        <Route
          path="/ads/:id"
          element={
            <Suspense fallback={<Spin />}>
              <AdsView />
            </Suspense>
          }
        />
        <Route
          path="/chat"
          element={
            <Suspense fallback={<Spin />}>
              <ChatView />
            </Suspense>
          }
        />
        <Route
          path="/chat/:id"
          element={
            <Suspense fallback={<Spin />}>
              <ChatView />
            </Suspense>
          }
        />
        <Route
          path="/chat/:id/:userId"
          element={
            <Suspense fallback={<Spin />}>
              <ChatView />
            </Suspense>
          }
        />
        <Route
          path="/profile"
          element={
            <Suspense fallback={<Spin />}>
              <ProfileView />
            </Suspense>
          }
        />
        <Route
          path="/business"
          element={
            <Suspense fallback={<Spin />}>
              <BusinessView />
            </Suspense>
          }
        />
        <Route
          path="/integrations"
          element={
            <Suspense fallback={<Spin />}>
              <IntegrationsView />
            </Suspense>
          }
        />
        <Route
          path="/debug"
          element={
            <Suspense fallback={<Spin />}>
              <DebugView />
            </Suspense>
          }
        />
        <Route
          path="/admin"
          element={
            <Suspense fallback={<Spin />}>
              <AdminViewAds />
            </Suspense>
          }
        />
        <Route
          path="/admin/ads"
          element={
            <Suspense fallback={<Spin />}>
              <AdminViewAds />
            </Suspense>
          }
        />
        <Route
          path="/admin/businesses"
          element={
            <Suspense fallback={<Spin />}>
              <AdminViewBusinesses />
            </Suspense>
          }
        />
        <Route path="*" element={<ErrorView />} />
      </Route>
      <Route
        path="/sign-in"
        element={
          <Suspense fallback={<Spin />}>
            <SignInView />
          </Suspense>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Suspense fallback={<Spin />}>
            <SignUpView />
          </Suspense>
        }
      />
      <Route
        path="/onboarding"
        element={
          <Suspense fallback={<Spin />}>
            <OnboardingView />
          </Suspense>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Suspense fallback={<Spin />}>
            <ForgotPasswordView />
          </Suspense>
        }
      />
    </>
  )
);
